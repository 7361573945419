import { axiosTNL, axiosTDMS } from "./axiosInstance";

export default {
    async processLogin(loginform) {
        //return axiosTNL.post("order/ValidateDealerLogin", loginform);
        return axiosTDMS.post("tdms/login", loginform);
    },
    async processAdminLogin(loginform) {
        //return axiosTNL.post("order/ValidateTNLLogin", loginform);
        return axiosTDMS.post("tdms/TNLLogin", loginform);
    },
    async updateAdminLogin(loginform) {
        return axiosTNL.post("order/UpdateTNLLogin", loginform);
    }
};
